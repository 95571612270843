<template>
	<div>
		<el-collapse v-model="activeNames" @change="handleChange">
			<el-collapse-item title="IP白名单" name="1">
				<div class="width80">
					<el-input v-model="ipInput" placeholder="请输入单个名单，按回车键添加" @keyup.native.enter="handleEnterIp"></el-input>
				</div>
				<div class="width80">
					<el-tag
						v-for="(tag,i) in ipList"
						:key="i"
						@close="handleIp(i)"
						closable>
						{{tag}}
					</el-tag>
				</div>
				<el-button class="submit-btn" type="primary" @click="submitIp">保存</el-button>
			</el-collapse-item>
			<el-collapse-item title="全球FAQ" name="2">
				<div class="FAQ-box">
					<div class="flex">
						<div class="flex1">
							<span>GKM ID</span>
							<el-input show-password auto-complete="new-password" v-model="faqObj.GKM_ID" placeholder="请输入GKM ID"></el-input>
						</div>
						<div class="flex1">
							<span>GKM Ppassword</span>
							<el-input show-password auto-complete="new-password" type="password" v-model="faqObj.GKM_Password" placeholder="请输入GKM Ppassword"></el-input>
						</div>
					</div>
					<div class="flex">
						<div class="flex1">
							<span>3DES Key</span>
							<el-input show-password auto-complete="new-password" v-model="faqObj.DES_Key" placeholder="请输入3DES Key"></el-input>
						</div>
						<div class="flex1">
							<span>国家代码</span>
							<el-input show-password auto-complete="new-password" v-model="faqObj.COUNTRY_Code" placeholder="请输入国家代码"></el-input>
						</div>
					</div>
					<div class="flex">
						<div class="flex1">
							<span>区域代码</span>
							<el-input show-password auto-complete="new-password" v-model="faqObj.AREA_Code" placeholder="请输入区域代码"></el-input>
						</div>
						<div class="flex1"></div>
					</div>

				</div>
				<el-button class="submit-btn" type="primary" @click="submitFaq">保存</el-button>
			</el-collapse-item>
			<el-collapse-item title="固件下载" name="3">
				<div class="FAQ-box">
					<div class="flex">
						<div class="flex1">
							<span>认证用户</span>
							<el-input show-password auto-complete="new-password" v-model="downObj.user" placeholder="请输入用户名"></el-input>
						</div>
						<div class="flex1">
							<span>用户密码</span>
							<el-input show-password auto-complete="new-password" type="password" v-model="downObj.pwd" placeholder="请输入密码"></el-input>
						</div>
					</div>
				</div>
				<el-button class="submit-btn" type="primary" @click="submitDown">保存</el-button>
			</el-collapse-item>
			<el-collapse-item title="日志下载" name="4">
				<div class="download-box">
					<el-date-picker
						v-model="datatime"
						type="datetimerange"
						value-format="yyyy-MM-dd HH:mm:ss"
						range-separator="至"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
						:default-time="['00:00:00', '23:59:59']">
					</el-date-picker>
				</div>
				<el-button class="download-btn" type="primary" @click="handleDownload">下载</el-button>
			</el-collapse-item>
			<el-collapse-item title="磁盘容量" name="5">
				<p v-for="(item,i) in diskList" :key="i">{{item}}</p>
			</el-collapse-item>
		</el-collapse>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				activeNames: ['1','2','3','4','5'],
				input: '',
				// IP
				ipInput: '',
				ipList: [],
				// faq
				faqObj: {
					DES_Key: '',
					AREA_Code: '',
					COUNTRY_Code: '',
					GKM_ID: '',
					GKM_Password: '',
				},
				// 固件下载
				downObj: {
					user: '',
					pwd: ''
				},
				datatime: [],
				diskList: []
			};
		},
		created() {
			this.getData();
		},
		methods: {
			handleChange(val) {

			},
			// 获取数据
			getData() {
				let that = this;
				that.$request.post(
					"sysBaseConfigListMap",
					false,
					{},
					function (r) {
						if (r.code == "0") {
							// that.ipList = r.data[0].val.split(',');
							// that.faqObj = JSON.parse(r.data[1].val);
							// that.downObj = JSON.parse(r.data[2].val);
							// that.diskList = JSON.parse(r.data[5].val);
							that.ipList = r.data.wip.split(',');
							that.faqObj = JSON.parse(r.data.faq);
							that.downObj = JSON.parse(r.data.firmwareDownload);
							that.diskList = JSON.parse(r.data.MemDisk);
						} else {
							that.$message.error(r.msg);
						}
					}
				);
			},
			// 输入ip
			handleEnterIp() {
				if(this.ipInput == '') {
					this.$message.error('请输入IP，再按回车键添加');
					return
				}
				this.ipList.push(this.ipInput);
				this.ipInput = '';
			},
			// 删除IP
			handleIp(i) {
				this.ipList.splice(i,1);
			},
			// 保存ip
			submitIp() {
				let that = this;
				that.$request.post(
					"sysBaseConfigUpdate",
					true,
					{
						id: '1',
						val: that.ipList.join(',')
					},
					function (r) {
						if (r.code == "0") {
							that.$message.success('保存成功');
						} else {
							that.$message.error(r.msg);
						}
					}
				);
			},
			submitFaq() {
				if(this.faqObj.GKM_ID == '') {
					this.$message.error('GKM ID不能为空');
					return
				}
				if(this.faqObj.GKM_Password == '') {
					this.$message.error('GKM Ppassword不能为空');
					return
				}
				if(this.faqObj.DES_Key == '') {
					this.$message.error('3DES Key不能为空');
					return
				}
				if(this.faqObj.COUNTRY_Code == '') {
					this.$message.error('国家代码不能为空');
					return
				}
				if(this.faqObj.AREA_Code == '') {
					this.$message.error('区域代码不能为空');
					return
				}
				let that = this;
				that.$request.post(
					"sysBaseConfigUpdate",
					true,
					{
						id: '2',
						val: JSON.stringify(that.faqObj)
					},
					function (r) {
						if (r.code == "0") {
							that.$message.success('保存成功');
						} else {
							that.$message.error(r.msg);
						}
					}
				);
			},
			submitDown() {
				if(this.downObj.user == '') {
					this.$message.error('认证用户不能为空');
					return
				}
				if(this.downObj.pwd == '') {
					this.$message.error('用户密码不能为空');
					return
				}
				let that = this;
				that.$request.post(
					"sysBaseConfigUpdate",
					true,
					{
						id: '3',
						val: JSON.stringify(that.downObj)
					},
					function (r) {
						if (r.code == "0") {
							that.$message.success('保存成功');
						} else {
							that.$message.error(r.msg);
						}
					}
				);
			},
			handleDownload() {
				if(this.datatime.length == 0) {
					this.$message.error('请选择开始结束日期');
					return
				}
				let that = this
				that.$request.post(
					"exportLog",
					true,
					{
						accessTimeSt: that.datatime[0],
						accessTimeEnd: that.datatime[1]
					},
					function (res) {
						if (res.code == '0') {
							const linkNode = document.createElement('a');
							linkNode.style.display = 'none';
							linkNode.href = res.data;
							document.body.appendChild(linkNode);
							linkNode.click();  //模拟在按钮上的一次鼠标单击
							URL.revokeObjectURL(linkNode.href); // 释放URL 对象
							document.body.removeChild(linkNode);
						} else {
							that.$message.error(res.msg);
						}
					}
				)
			}
		}
	}
</script>
<style lang="scss" scoped>
/deep/ .el-collapse-item__header{
	background-color: rgba(199, 227, 249, 0.2);
	box-sizing: border-box;
	padding-left: 20px;
	font-size: 16px;
	font-weight: 500;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}
.width80{
	width: 70%;
	margin: 10px;
}
.el-tag{
	margin: 10px;
}
.submit-btn{
	float: right;
	margin-top: -50px;
}
.FAQ-box{
	width: 90%;
}
.flex{
	display: flex;
	.flex1{
		flex: 1;
		box-sizing: border-box;
		padding-right: 10px;
		margin-bottom: 10px;
	}
}
.download-btn{
	float: right;
	margin-top: -40px;
}
.download-box{
	box-sizing: border-box;
	padding-top: 20px;

}
</style>
